import {createStore, combineReducers} from 'redux';

var kycState = {
	winWid: document.documentElement.clientWidth,
	winHgt: document.documentElement.clientHeight,
	apiTimestamp: {},
	GM_API: '',
	LOGIN_API: '',
	DOB_API: '',
	BM_token: '',
	BM_apitoken: '',
	AL_user: '',
	AL_userid: '',
	AL_userrole: '',
	AL_roleId: '',
	AL_token: '',
	BM_custname: '',
	BM_mobile: '',
	BM_idtype: '',
	BM_journeyid: '',
	BM_cif: '',
	BM_nonstp: '',
	BM_renewType: '',
	BM_lang: 'En',
	BM_jsessionid: '',
	BM_bway: '',
	BM_step: '',
	BM_step_status: '',
	BM_client: '',
	BM_otp_locked: '',
	BM_ocr_attempts: '',
	responseStatus: false,
	responseStatusMsg: '',
	location: ''
}

const kycStateData = (state = kycState, action) => {
	switch (action.type){
		case 'CHANGE_SCREENDIM':
			return {
				...state,
				winWid: action.winWid,
				winHgt: action.winHgt
			}
		case 'RESPONSE_ERROR':
			return {
				...state,
				responseStatus: action.status
			}
		case 'API_RESPONSE_ERROR':
			return {
				...state,
				responseStatusMsg: action.errorMsg
			}

		default:
			return state
	}
}
export const KYCReducer = combineReducers({
	KYCState: kycStateData
});

export const kycStore = createStore(KYCReducer)

export default KYCReducer;