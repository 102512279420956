let initialState = {
    fetchmenudata: []

}

const MenuReducer =  (state = {fetchmenudata:[] }, action) => {
    switch (action.type) {
        case "FETCH_MENU":
            return {
                ...state,
                fetchmenudata: action.fetchmenudata,
            };



        default:
            return state;
    }
};


export default MenuReducer;
