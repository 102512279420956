import React, {useEffect, useReducer, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

const Pagesidebar = (props) => {

    const [selectedmenu, setSelectedMenu] = useState(props.mainmenu);
    const [selectedsubmenu, setSelectedSubMenu] = useState(props.menu);
    const [menuItems, setMenuItems] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {menu: '', master: '' }
    );

    let history = useHistory();

    const dispatch = useDispatch();
    const menudata = useSelector(state => state.MenuReducer.fetchmenudata) ;
    const activeSetSubenu = (submenu='') => {
        setSelectedSubMenu(submenu);
    }


    useEffect(() => {
        if(menudata !== undefined){
            setMenuItems({ "menu" : menudata.cmenu, "master" : menudata.pmenu});
        }

    }, [menudata]);



    const activeSet = (menu) => {

        if(selectedmenu=== menu ){
            setSelectedMenu('');
        }else{
            setSelectedMenu(menu);
        }
    }


    return (
        <React.Fragment>


            <div className="pageSidebar" className={`pageSidebar ${props.showsidebar ? "show" : "hide"} `}>
                <ul className="sidebarList toggleSideBar__contents">
                    <li id="listItem-0" onClick={() => activeSet('Dashboard')}
                        className={`hasInnerList ${(selectedmenu) === 'Dashboard' ? "activeIn" : ""}  closed`}>
                        <Link to="/dashboard">{'Dashboard'}</Link></li>
                    <li id="listItem-1" onClick={() => activeSet('Report')}
                        className={`hasInnerList ${(selectedmenu) === 'Report' ? "activeIn" : ""}  closed`}>
                        <Link to="/report">{'Report'}</Link></li>
                    <li id="listItem-2" onClick={() => activeSet('Masters')} menu={selectedmenu}
                        className={`iconMasters ${selectedmenu == 'Masters' ? "active" : ""}  `}>
                        {'Masters'}</li>
                    <ul>
                        <li className={`hasInnerList ${(selectedsubmenu) === 'Branch' ? "activeIn" : ""} `}>
                            <Link to="/list-branch"
                                  onClick={() => activeSetSubenu
                                  ('Branch')}>{'Branch'}
                            </Link>
                        </li>
                    </ul>



                </ul>
            </div>

        </React.Fragment>

    )
}
export default Pagesidebar
