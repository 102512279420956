import React, { useReducer } from 'react';
import PreLoader from "../assets/images/loader_01.gif";


const Loader = (props) => {

    return (

            <div className={`csModal ${props.message === '' ? '' : 'flexCol'} csModal--active`}>

                <div className="imgCntnr">
                    <img width={50} src={PreLoader} className="loader" alt="" />
                </div>
                {props.message !== '' &&
                <div className="msgCntnr">
                    <p dangerouslySetInnerHTML={{__html:props.message}}></p>
                </div>
                }
            </div>

    )
}

export default Loader
