import {userService} from "../services/user.Services";

export const ListDashboard = (filterType,status,nationalty,perpage,page, stage) => dispatch => {
    let dashboaddata = userService.ListDashboard(filterType,status,nationalty,perpage,page, stage);
    dispatch({
        type: 'DASHBOARD_LOADER',
        loading:true,

    });
    dashboaddata.then(dashboad => {
        if(dashboad.data  !== undefined) {
            dispatch({
                type: 'DASHBOARD',
                dashboarddata: dashboad.data.data,
                dashboardcount: dashboad.data.count

            })
            dispatch({
                type: 'DASHBOARD_LOADER',
                loading:false,

            });
        }
    })
};
export const ListExportData = (filterType,status,nationalty) => dispatch => {
    let exportdata = userService.ListExportData(filterType,status,nationalty);
    dispatch({
        type: 'DASHBOARD_EXPORT_LOADER',
        loading:true,

    });
    exportdata.then(dashboad => {
        if(dashboad.data  !== undefined) {
            dispatch({
                type: 'DASHBOARD_EXPORT',
                dashboarddata: dashboad.data.data,
                dashboardcount: dashboad.data.count

            })
            dispatch({
                type: 'DASHBOARD_EXPORT_LOADER',
                loading:false,

            });
        }
    })
};
export const ListChart = (status) => dispatch => {

    let listchartdata = userService.Listgraphdata(status);
    listchartdata.then(listchartdata => {
        if(listchartdata.data  !== undefined) {
            dispatch({
                type: 'LIST_CHART',
                listcharts: listchartdata.data

            })
        }
    })
};
export const ListBranches = (searchval,searchby) => dispatch => {
    let listbranchdata = userService.ListBranches(searchval,searchby);

    listbranchdata.then(listbranchdata => {
        if(listbranchdata.data  !== undefined){
            dispatch({
                type: 'LIST_BRANCH',
                listbranchdata: listbranchdata.data

            })

        }
    })

};
export const UpdateBranch = (id, code, branch, active) => dispatch => {
    dispatch({
        type: 'UPDATE_BRANCH',
        updatebranch: false

    })
    let updatebranchdata = userService.UpdateBranch(id, code, branch, active);

    updatebranchdata.then(updatebranchdata => {
        if(updatebranchdata.data  !== undefined){

            if(updatebranchdata.data.success  === true) {
                dispatch({
                    type: 'UPDATE_BRANCH',
                    updatebranch: updatebranchdata.data.success

                })
                let listbranchdata = userService.ListBranches();

                listbranchdata.then(listbranchdata => {
                    if (listbranchdata.data !== undefined) {
                        dispatch({
                            type: 'LIST_BRANCH',
                            listbranchdata: listbranchdata.data

                        })

                    }
                })
            }

        }
    })

};
export const AddBranches = (code, branch, active) => dispatch => {
    let addbranchdata = userService.AddBranch(code, branch, active);

    addbranchdata.then(addbranchdata => {
        if(addbranchdata.data  !== undefined){
            //addbranchdata.data.status = 'success'
            dispatch({
                type: 'ADD_BRANCH',
                addbranchdata: addbranchdata.data

            })

        }
    })

};














