 import axios from 'axios';
 import {kycStore} from '../reducers/KYCState';
 import {getToken, getRandomAESKey} from "../utilities/AuthService";
 import ErrorMessage from '../components/ErrorMessage';
 import {GM_API} from "../constants";


 const sc_k = 'crmEp5ww3wV/5I1lL8MACGR8w8WyT33ghVJxRsvS5Maij+wIPJKhXvxQQ3bkUjR26n9DVWxlEN8MeJvmwsO61Q==';
 const cs_rsa_k = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDmY8LtmhQ6MNO6WF3prQLQcte2
j2kO3WjJbIeNxuwUt8ywYq8F/cFjLoKozqKeI8RTcP4lHcLK4k2H3cI/XU2CBV2q
d5LurcumVKDdBaCyeDfN9/hMC00k/0Wehl6oIU7W1VAjA9C2V45sak9P7WdV/Qz4
IHMia3Oscvmc/wMsBwIDAQAB
-----END PUBLIC KEY-----`
// import {CC_API, GM_API} from '../constants/index'
//
//  let CC_API = '';
//  let GM_API = '';
//
//
//  if(String(document.location).indexOf('sitagent1') > -1){
//      CC_API   =  'https://sitagent1.bmoman.bankmuscat.com:1443/cc/' ;
//      GM_API=  'https://sitagent1.bmoman.bankmuscat.com:1443/ocr/' ;
//  }else if(String(document.location).indexOf('uatrekycagent1') > -1){
//      CC_API   =  'http://uatrekycagent1.bankmuscat.com:1443/cc/' ;
//      GM_API=  'http://uatrekycagent1.bankmuscat.com:1443/ocr/' ;
//  }
//  else{
//      CC_API   =  'https://bom.labsls.com/' ;
//      GM_API=  'https://digivaldemo.gieom.com/' ;
//  }
//  console.log(document.location)
//  console.log(CC_API, GM_API)


export const userService = {
    init,
    ListDashboard,
    ListExportData,
    getConfig,
    decryptDataFromOcr,
    encryptDataForOcr,
    Listgraphdata,
    getAgentapi,
    ListBranches,
    UpdateBranch,
    AddBranch
};


// Added interceptor start /////////////////////////

 const apiParams = {

     'config.json': {
         fName: 'config.json',
         maxRetries: 3,
         iteration: 0
     } ,
     'dashboardnew': {
         fName: 'ListDashboard',
         maxRetries: 3,
         iteration: 0
     } ,
     'barchartdata': {
         fName: 'Listgraphdata',
         maxRetries: 3,
         iteration: 0
     },
     'ListBranch': {
         fName: 'ListBranches',
         maxRetries: 3,
         iteration: 0
     },
     'UpdateBranch': {
         fName: 'UpdateBranch',
         maxRetries: 3,
         iteration: 0
     },
     'AddBranch': {
         fName: 'AddBranch',
         maxRetries: 3,
         iteration: 0
     }
 }




 const getApiNameFromUrl = (url) => {
     let action = '';
     let path = url.split('?')[0].split('/');
     if(path[path.length-1] === ''){
         return path[path.length-2]
     }
     else{
         return path[path.length-1]
     }

 }


 axios.interceptors.request.use( (req) => {
     req.timeout = 240000;

     let action = getApiNameFromUrl(req.url);
     if(apiParams[action]) {
         apiParams[action]['iteration'] = apiParams[action]['iteration'] + 1;
     }
     if(
         action !== 'config.json'
         ) {

         if (req) {
             try {

                 let ts_ = new Date().getTime()
                 req.data.ts_ = ts_
                 let cs_aes_k = getRandomAESKey()
                 let jsEncrypt = new window.JSEncrypt();
                 jsEncrypt.setPublicKey(cs_rsa_k);
                 let rsa_enc_cs_aes_k = jsEncrypt.encrypt(cs_aes_k);
                 kycStore.getState().KYCState.apiTimestamp[action] = {ts_: ts_, k: cs_aes_k, d: req.data}
                 let encrypted_param = JSON.parse(window.CryptoJSAesJson.encrypt(req.data, cs_aes_k))
                 let encrypted_str = encrypted_param.ct + '.' + encrypted_param.s + '.' + encrypted_param.iv + '.' + rsa_enc_cs_aes_k

                 req.data = encrypted_str


             } catch (e) {

             }

         }

     }
     return req;

 }, function (error) {
     // Do something with request error
     <ErrorMessage/>
     return Promise.reject(error);
 });

 // Add a response interceptor
 axios.interceptors.response.use((res) =>  {
     let action = getApiNameFromUrl(res.config.url);
     if(
         action !== 'config.json'
     ){
         try {
             let req_ts =  kycStore.getState().KYCState.apiTimestamp[action].ts_


             let resarr = res.data.split('.')
             let ct = resarr[0]
             let s = resarr[1]
             let iv = resarr[2]
             let enObj = {ct: ct, s: s, iv: iv}
             let aes_k = kycStore.getState().KYCState.apiTimestamp[action].k

             let decrypted = window.CryptoJSAesJson.decrypt(JSON.stringify(enObj), aes_k)

             res.data = JSON.parse(decrypted)


             if(req_ts !== res.data.ts_){
                 res.data = 'encrTSerr'
                 if(getToken().apitoken !== '')
                    // createAccessLog('API', 'Response ts_ not matching ' + action , getToken().cif, getToken().journeyid, getToken().client)
                 kycStore.dispatch({
                     type: 'RESPONSE_ERROR',
                     status: true
                 })
             }
             /*kycStore.dispatch({
                 type: 'RESPONSE_ERROR',
                 status: false
             })*/
         } catch (e) {
             kycStore.dispatch({
                 type: 'RESPONSE_ERROR',
                 status: true
             })
         }


     } else {
     }
     return res;
 },(err) => {


     let action = err.config ? getApiNameFromUrl(err.config.url) : '';
     if(apiParams[action] && err.config && apiParams[action]['iteration'] < apiParams[action]['maxRetries']) {
         //console.log('1');
         let c = err.config
        c.data = kycStore.getState().KYCState.apiTimestamp[action].d
         kycStore.dispatch({
             type: 'API_RESPONSE_ERROR',
             status: true,
             errorMsg : err
         })
         return axios.request(c);
     } else {

         return Promise.reject(err);
     }

 });



 function encrScript4Loaded(){
     let j =
         {
             "DOB_API":"https://uatrekycagent1.bankmuscat.com:2443/ccdob/"
         }


     let encrypted = window.CryptoJSAesJson.encrypt(j, sc_k)
     let encr_b64 = btoa(JSON.stringify(encrypted))
    // console.log(encr_b64);

     if(kycStore.getState().KYCState.DOB_API == 'undefined') {
         getConfig();
     }
 }
 function encrScript3Loaded(){
     const script = document.createElement("script");
     script.async = true;
     script.src = "assets/js/crypto-js.min.js";
     script.onload = () => encrScript4Loaded();
     document.body.appendChild(script);
   //  getAgentapi()
 }
 function encrScript2Loaded(){
     //console.log('encrScript2Loaded')
     const script = document.createElement("script");
     script.async = true;
     script.src = "assets/js/jsencrypt.min.js";
     script.onload = () => encrScript3Loaded();
     document.body.appendChild(script);
 }
 function encScript1loaded(){
     //console.log('encScript1loaded')
     const script = document.createElement("script");
     script.async = true;
     script.src = "assets/js/cryptojs-aes-format.js";
     script.onload = () => encrScript2Loaded();
     document.body.appendChild(script);
 }
 function init(){
     const script = document.createElement("script");
     script.async = true;
     script.src = "assets/js/cryptojs-aes.min.js";
     script.onload = () => encScript1loaded();
     document.body.appendChild(script);
     //console.log('init')
 }
// Added interceptor end /////////////////////////
// Added OCR interceptor start /////////////////////////
 function encryptDataForOcr(post, action){
     let ts_ = new Date().getTime()
     post.ts_ = ts_

     let key = getRandomAESKey(10)
     let iv = getRandomAESKey(10)
     let key_ = window.CryptoJS.enc.Utf8.parse(key);
     var iv_ = window.CryptoJS.enc.Utf8.parse(iv)
     var encrypted = window.CryptoJS.AES.encrypt( JSON.stringify( post), key_, { iv: iv_, mode: window.CryptoJS.mode.CBC});
     // console.log(encrypted );
     encrypted =encrypted.toString();
     //console.log('key', key)
     //console.log('iv', iv)
     // console.log(encrypted );

     let jsEncrypt = new window.JSEncrypt();
     jsEncrypt.setPublicKey(cs_rsa_k);
     let rsa_enc_cs_aes_k = jsEncrypt.encrypt(key);

     kycStore.getState().KYCState.apiTimestamp[action] = {ts_: ts_, k: key+'.'+iv}

     let str_to_server = encrypted + '.' + iv + '.' + rsa_enc_cs_aes_k
     // console.log(str_to_server);

     return str_to_server ;
 }
 function decryptDataFromOcr(res, action){
     let req_ts =  kycStore.getState().KYCState.apiTimestamp[action].ts_
     let aes_k_iv = kycStore.getState().KYCState.apiTimestamp[action].k
     var encrypted_s = res;

     var key_s = aes_k_iv.split('.')[0]
     let key_s_ = window.CryptoJS.enc.Utf8.parse(key_s);

     let iv_s = aes_k_iv.split('.')[1]
     var iv_s_ = window.CryptoJS.enc.Utf8.parse(iv_s)

     //console.log('key.iv', aes_k_iv)

     var decrypted =  window.CryptoJS.AES.decrypt(encrypted_s, key_s_, { iv: iv_s_, mode: window.CryptoJS.mode.CBC});
     decrypted = decrypted.toString(window.CryptoJS.enc.Utf8);
     return decrypted;
 }
// Added OCR interceptor end /////////////////////////


 function getConfig(){

     axios.get('config.json').then(config => {
         if(config.data !== undefined && config.data !== null){

             let jsonToDcr = JSON.parse(atob(config.data))
             let dcrData = window.CryptoJSAesJson.decrypt(jsonToDcr, sc_k);
             /*localStorage.setItem('BM_PWA_API', dcrData.PWA_API)
             localStorage.setItem('BM_GM_API', dcrData.GM_API)
             localStorage.setItem('BM_BASE_URL', dcrData.BASE_URL)*/
             kycStore.getState().KYCState.DOB_API = dcrData.DOB_API;

             // kycStore.getState().KYCState.LOGIN_API = 'https://bomjune.labsls.com/agentlogin/verifylogin'
             // kycStore.getState().KYCState.PWA_API = 'https://bom.labsls.com/'
             /*kycStore.dispatch({
                 type: 'CONFIG_LOADED',
                 PWA_API: dcrData.PWA_API,
                 GM_API: dcrData.GM_API,
                 BASE_URL: dcrData.BASE_URL
             })
             interactions.appendDebug('Config loaded')
             interactions.appendDebug(kycStore.getState().KYCState.debugSpacer)

             getJourneyId()

             testApi()*/

         }


     }).catch(err => {
     //    alert(3333);


     })
 }

 function getAgentapi(){
     return kycStore.getState().KYCState.DOB_API
 }



 function ListDashboard(filterType='',status='',nationality='',perpage, page, stage = ''){
     /*console.log({
         "filterType": filterType,
         "status": status,
         "nationality": nationality,
         "perpage": perpage,
         "page": page,
         "stage": stage
     })*/
     return axios.post(getAgentapi() + 'masters/dashboardnew', {
         "filterType": filterType,
         "status": status,
         "nationality": nationality,
         "perpage": perpage,
         "page": page,
         "stage": stage
     })
 }
 function ListExportData(filterType='',status='',nationality=''){
     return axios.post(getAgentapi() + 'masters/exportnew', {
         "filterType": filterType,
         "status": status,
         "nationality": nationality
     })
 }
function Listgraphdata(status=''){
    
	return axios.post(getAgentapi() +'masters/barchartdata', {
        "status": status
    })
 }
 function ListBranches(searchval='',searchby=''){
     return axios.post(getAgentapi() + 'masters/branches', {
         "searchval": searchval,
         "searchby": searchby
     },{headers: {"authorization": getToken().apitoken}})
     //return axios.get('branches.json')

 }
 function UpdateBranch(id, code, branch, active){
    /* console.log({
         "idno": id,
         "c": code,
         "en": branch,
         "active": parseInt(active)
     })*/
     return axios.post(getAgentapi() + 'masters/updatebranch', {
         "idno": id,
         "c": code,
         "en": branch,
         "active": parseInt(active)
     },{headers: {"authorization": getToken().apitoken}})
 }
 function AddBranch(code, branch, active){
     return axios.post(getAgentapi() + 'masters/addbranch', {
         "c": code,
         "en": branch,
         "active": parseInt(active)
     },{headers: {"authorization": getToken().apitoken}})
 }
