import {combineReducers} from 'redux';
import LoginReducer from './LoginReducer'
import UserReducer from "./UserReducer";
import MenuReducer from "./MenuReducer";
import KYCReducer from "./KYCState";

const app = combineReducers({

    LoginReducer,
    UserReducer,
    MenuReducer,
    KYCReducer

});

export default app;
