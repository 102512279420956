import decode from 'jwt-decode';
import {kycStore} from '../reducers/KYCState';

export  function handshake(webtoken) {
    /* const user = jwt(webtoken);
    console.log(user) ;
    setToken(webtoken,user)
    console.log(webtoken);
    setToken(webtoken) ;  */
    const user = decode(webtoken);
    setToken(webtoken,user)
    return Promise.resolve(webtoken);
}


export  function authservicelogin(id,logindata,token='') {
    /* setToken(token,user_id,name,permission,screens) */
    setToken(logindata,token) ;
    return Promise.resolve(id);
}



function setToken(logindata,token='') {
    kycStore.getState().KYCState.AL_user = logindata.UserName
    kycStore.getState().KYCState.AL_userid = logindata.UserUniqueId
    kycStore.getState().KYCState.AL_userrole = logindata.RoleName
    kycStore.getState().KYCState.AL_roleId = logindata.RoleId
    kycStore.getState().KYCState.AL_token = token

    // localStorage.setItem('AL_user', logindata.UserName)
    // localStorage.setItem('AL_userid', logindata.UserUniqueId)
    // localStorage.setItem('AL_userrole', logindata.RoleName)
    // localStorage.setItem('AL_roleId', logindata.RoleId)
    // localStorage.setItem('AL_token', token)


}

export function getToken() {
    var user = [];
    user['name'] = kycStore.getState().KYCState.AL_user;
    user['id'] = kycStore.getState().KYCState.AL_userid;
    user['role'] = kycStore.getState().KYCState.AL_userrole;
    user['roleid'] = kycStore.getState().KYCState.AL_roleId;
    user['apitoken'] = kycStore.getState().KYCState.AL_token;

    // user['name'] = localStorage.getItem("AL_user")
    // user['id'] = localStorage.getItem("AL_userid")
    // user['role'] = localStorage.getItem("AL_userrole")
    // user['roleid'] = localStorage.getItem("AL_roleId")
    // user['apitoken'] = localStorage.getItem("AL_token")
    return user
}

export function authservicelogout() {
    localStorage.removeItem('AL_user');
    localStorage.removeItem("AL_userid");
    localStorage.removeItem("AL_userrole");
    localStorage.removeItem("AL_roleId");
    localStorage.removeItem("AL_token");
}

export function getUrlSegment(segment) {
    var url = window.location.pathname.split('/');
    return url[segment];
}
export function getRandomAESKey(length=80) {
    // let length=80
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for ( var i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return btoa(result);
}