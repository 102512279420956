import {authService} from "../services/auth.services";
import {userService} from "../services/user.Services";
import { authservicelogin,authservicelogout  } from '../utilities/AuthService'


export const UserLogin = (username, password) => dispatch => {
    let logindata = authService.login(username, password);
    logindata.then(logindata => {
        if(logindata.data  !== undefined) {
        let cif = '';
        let sectionmsg = 'Login Success';
        if (logindata.data.status === "success") {
            authservicelogin(logindata.data.UserId, logindata.data.UserInfo, logindata.data.userToken);
            //  userService.CreateAgentLoginLog('Login','','Login','Logged In Successfully');
            dispatch({
                type: 'LOGIN_SUCCESS',
                logindata: logindata.data.status

            })

            //     window.History.push('/dashboard');
        } else {
            //      userService.CreateAgentLoginLog('Login','','Login','Logged In Failed');
            dispatch({
                type: 'LOGIN_FAILED',
                validdataerrors: logindata.data.status

            })
        }

    }



    })

};
export const getConfig = () => dispatch => {
    let siteConfig = authService.getConfig();
    siteConfig.then(config => {
        localStorage.setItem('CC_API', config.data.CC_API)
        localStorage.setItem('GM_API', config.data.GM_API)
        localStorage.setItem('LOGIN_API', config.data.LOGIN_API)

    }).catch(config => {
        console.log( config.error)
    })

};


export const UserNewLogin = () => dispatch => {
    localStorage.setItem('id_token', '11111')
    //console.log(89900000);
    dispatch({
        type: 'LOGIN_TEST_SUCCESS',
        logindatatest: 'Success'

    });
};

export const UserLogout = () => dispatch => {
    dispatch({
        type: 'LOGIN_TEST_SUCCESS',
        logindatatest: ''

    });
   localStorage.setItem('id_token', '');
  // window.location = "/#";

};
