import React, {useState, useEffect, useReducer} from 'react';
import {Link, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {Buffer}  from 'buffer';
import {
    ListBranches,
    UpdateBranch,
    EditSettingsData,
    ListUser, ListRolesectionPermission
} from "../actions/User";

import Selectbox from "../components/Selectbox";
import Pagesidebar from "../components/Pagsidebar";
import Navbar from "../components/Navbar";
import {getToken} from "../utilities/AuthService";
import Loader from "../components/Loader";
import {CSVLink} from "react-csv";


const ListBranch = () => {
    const [hasError, setErrors] = useState(false);
    const [yesnovalue, setYesnovalue] = useState([{"label": "Yes", "value": 1},{"label": "No", "value": 0}]);
    const [searchbyvalue, setSearchbyvalue] = useState([{"label": "Branch", "value": "branch"},{"label": "Branch Code", "value": "code"}]);
    const [modalIn, setModalIn] = useState(false);
    const [branches, setBranches] = useState([]);
    const [branch, setBranch] = useState([]);
    const [selbranchid, setSelbranchid] = useState('');
    const [selectedcountry, setSelectedcountry] = useState([]);
    const [sidebar, setSidebar] = useState(true);
    const [showsidebar, setShowsidebar] = useState(true);
    const [filterlist, setFilterlist] = useState([]);
    const [user, setUser] = useState(getToken().name);
    const [userid, setUserid] = useState(getToken().id);
    const [submit, setSubmit] = useState(false );
    const [status, setStatus] = useState('' );
    const [btnstatus, setButtonStatus] = useState(true );
    const [selmastersdata, setSelMastersdata] = useState([]);
    const [tempBranches, setTempBranches] = useState([]);
    const [statusloader, setStatusloader] = useState(false);
    const [searchby, setInputSearchby] = useState('');
    const [searchval, setSearchVal] = useState('' );

    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {branch: '',branchar : '',branchcode:'', selactive:'', seldeleted:''}
    );

    const branchdata = useSelector(state => state.UserReducer.listbranchdata.data) ;
    const updatebranch = useSelector(state => state.UserReducer.updatebranch) ;

    let history = useHistory();
    const dispatch = useDispatch();


    useEffect(() => {
        if(!localStorage.getItem('id_token')){
            window.location = "/";
        }
        setStatusloader(true);
        dispatch(ListBranches(searchval,searchby)) ;
    },[dispatch]);

    useEffect(() => {
        if(branchdata) {
            setStatusloader(false);
            setBranches(branchdata)
        }

    },[branchdata]);








    useEffect(() => {

        if(updatebranch === true  ){
            setStatusloader(false);
            setStatus('success')
        }

    },[updatebranch]);


    const toggleModal = (id, branch) => {
        setStatus(true);

        if(id == 0){
            setButtonStatus(false);
            dispatch(ListBranches(searchval,searchby)) ;
            //dispatch(AgentLoginLog('Masters', 'Country', 'Edit', 'Country Edit modal close'));
        }else{
            setStatusloader(true);
            setButtonStatus(true);
            setSelbranchid(id) ;
            setBranch(branch)
            setInputValues({ "branch" : branch.en, "branchar" : branch.ar, "branchcode" : branch.c, "selactive" : branch.active || 0 });
            setStatusloader(false);

        }


        document.getElementById('root').classList.toggle('scrollOut');
        setModalIn(!modalIn);

    }

    const handleInput = (e) =>{
        setInputValues({ [e.target.name]: e.target.value }) ;
    }

    const handleChange = (value,name) =>{
        setInputValues({ [name]: value });
    }

    const resetForm = () => {
        setInputValues({ "branch" : '',"branchar": '', "branchcode" : '', "selactive" : ''});
    }
    const submitForm = () => {
        //dispatch(EditSettingsData(selsettingsid,inputValues.settingskey,inputValues.keyvalue)) ;
        setStatusloader(true);
        setStatus('')
        const myObj = {
            "id": selbranchid,
            "en": inputValues.branch,
            "ar": inputValues.branchar,
            "c":inputValues.branchcode,
            "active": inputValues.selactive
        };

        setSubmit(true);
        dispatch(UpdateBranch(selbranchid, inputValues.branchcode, inputValues.branch, inputValues.selactive)) ;

    }

    const changeNav = () =>{
        setShowsidebar(!showsidebar)
    }
    const handleFilterChange = (value,name) =>{
        setInputSearchby(value);
    }
    const searchValChange =(searchval)=>{
        setSearchVal(searchval);
        dispatch(ListBranches(searchval,searchby)) ;
    }
    useEffect(() => {
        let tempBranchesData =[];
        let tmpBranch = (branches != undefined && branches.length > 0) ?  branches : [];
        tempBranchesData = JSON.parse(JSON.stringify(tmpBranch));
        tempBranchesData.forEach(object => {
            delete object['id'];
            delete object['IsDeleted'];
            if(object['active'] == 1){
                object['status'] = 'Active'
            }else{
                object['status'] = 'InActive'
            }
            delete object['active'];

        });
        setTempBranches(tempBranchesData);

    },[branches]);
    return (
        <React.Fragment>
            <Pagesidebar menu="Branch" mainmenu="Masters" showsidebar={showsidebar} />
            <div className="pageSidebarOffset">
                <navbar className="pageNav">
                    <Navbar pagettl="Masters"  showsidebar={true} filter="No" search="No"   />
                </navbar>


                <div className="roundBox">
                    {statusloader &&
                    <Loader/>
                    }
                    <div className="roundBox__head">
                        <h4>Branches ({branches.length})</h4>
                        {tempBranches.length > 0 &&
                        <CSVLink
                            data={tempBranches}
                            filename={"BranchReport.xls"}
                            className={"btn btn--primaryG btn--sm btn--capsule"}
                            target="_blank"
                        >
                            Export as XLS
                        </CSVLink>
                        }
                        <Link to="/add-branch" className="btn btn--primaryG btn--capsule">(+) Branch</Link>
                    </div>

                    <div className="tableWrapper scrollable">
                        <table className="csTable">
                            <thead>
                            <tr>
                                <th>

                                    Sl No
                                </th>
                                <th>
                                    Branch
                                </th>
                                {/*<th>
                                    Branch Arabic
                                </th>*/}
                                <th>
                                    Branch Code
                                </th>
                                <th>
                                    Status
                                </th>
                                <th></th>

                            </tr>
                            </thead>
                            <tbody>
                            { branch && branches.map((item,index) => {
                                let branchAr = item.ar ? item.ar : '';
                                return(
                                    <tr key={`branch-${index}`}>
                                        <td>

                                            {index+1}
                                        </td>
                                        <td>
                                            {item.en}
                                        </td>
                                        {/*<td>
                                            {item.ar}
                                        </td>*/}
                                        <td>
                                            {item.c}
                                        </td>

                                        <td>
                                            {item.active == 1 ? 'Active' : 'Inactive'}
                                        </td>
                                        <td>

                                            <span className="iconEdit" onClick={() => toggleModal(item.id, item)}/>

                                        </td>
                                    </tr>
                                )
                            } ) }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                modalIn && (

                    <div className={`csModal csModal--sm csModal--active`}>
                        <div className="csModal__content">
                            {statusloader &&
                            <Loader/>
                            }
                            <span className="csModal__out" onClick={() => toggleModal('0')}/>

                            <h3>Edit Branch</h3>


                            <div className="csForm__item">
                                <label className="csForm__item__label">Branch</label>
                                <input value={inputValues.branch} name="branch" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>

                            {/*<div className="csForm__item">
                                <label className="csForm__item__label">Branch Arabic</label>
                                <input value={inputValues.branchar} name="branchar" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>*/}

                            <div className="csForm__item">
                                <label className="csForm__item__label">Branch Code</label>
                                <input value={inputValues.branchcode} name="branchcode" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>

                            <Selectbox  label="Active" name="selactive"  values={yesnovalue}   onChange={handleChange}  selvalue={inputValues.selactive.toString()} />


                            { status === "success"  ? (
                                <div> <span className="messageIn">Branch updated</span></div>
                            ) : ''
                            }
                            {btnstatus &&
                                <div className="flexCC">

                                    <span className="btn btn--primaryG btn--sm btn--capsule"
                                          onClick={() => submitForm()}>UPDATE</span>

                                </div>
                            }

                        </div>
                    </div>
                )
            }
        </React.Fragment>
    )
}

export default ListBranch
