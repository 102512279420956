import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route  } from 'react-router-dom';
import {Provider} from 'react-redux';
import configureStore from './configureStore';


import Login from "./pages/Login" ;
import Dashboard from "./pages/Dashboard" ;
import Report from "./pages/Report" ;
import ListBranch from "./pages/ListBranch";
import AddBranch from "./pages/AddBranch";


import './assets/css/fonts.css';
import './assets/css/template.css';

import Layout from "./components/Layout";
import {userService} from "./services/user.Services";
import {kycStore} from './reducers/KYCState';


const store = configureStore();

class App extends Component {
    componentDidMount() {
        //console.log(this.SessionContext)
            userService.init();
       this.BrowserReload();
        window.onunload = function () {
            window.location.reload(true);
            localStorage.clear();
            window.location.href = ('/');

        }

    }
    BrowserReload = () => {
        window.onbeforeunload = function (e) {
            e.preventDefault();
            return true
        };
    }

    render() {
        return (
            <Provider store={store}>

            <Router>

                <Switch>
                    <Route path="/" exact component={Login}/>
                    <Route path="/dashboard" exact component={Dashboard}/>
                    <Route path="/report" exact component={Report}/>
                    <Route path="/list-branch" exact component={ListBranch} />
                    <Route path="/add-branch" exact component={AddBranch} />
                </Switch>
            </Router>

            </Provider>
        )
    }
}

export default App;
