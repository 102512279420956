let initialState = {
    Validdata: [],
    logindata: ''
}

const LoginReducer =  (state = {Validdata:[]}, action) => {
    switch (action.type) {
        case "LOGIN_FAILED":
            console.log(action);
            return {
                ...state,
                Validdata: action.validdataerrors,
            };
            break;
        case "LOGIN_SUCCESS":
            return {
                ...state,
                logindata: action.logindata,
            };
            break;

        default:
            return state;
    }
};


export default LoginReducer;
