import React, {useEffect, useReducer, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useDispatch} from "react-redux";
import logo from '../assets/images/logo.png';
import useravatar from "../assets/images/user.png";

import {UserLogout} from "../actions/Authorization";


const Navbar = (props) => {
    const [userOption, setUserOption] = useState(false)

    const dispatch = useDispatch();
    let history = useHistory();


    const logout = () =>{
        dispatch(UserLogout()) ;
        // localStorage.clear();
        history.push('/');

    }


    return (
        <React.Fragment>

            <div className="pageNav__left">

                <h2>{props.pagettl}</h2>
            </div>.
            <div className="pageNav__middle">
            </div>
            <div className="pageNav__right">
                <div className="userBlock"   onClick={() => setUserOption(!userOption)}  >
                    <div className="userBlock__details">
                        <h6 className="userName">Agent Login</h6>
                        <p className="userRole">{'Admin'}</p>
                    </div>
                    <div className="userBlock__image">
                        <img src={useravatar} className="userBlock__image__in" alt="user" />
                    </div>
                    {
                        userOption && (
                            <div className="userBlock__logout"  id="userOptionList">
                                <ul className="userOptionList"  onClick={logout}>
                                    <li>
                                        <span className="">Log out</span>
                                    </li>
                                </ul>
                            </div>
                        )}

                </div>
            </div>


        </React.Fragment>

    )
}
export default Navbar
