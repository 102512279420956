let initialState = {
    dashboarddata: [],
    dashboardcount: [],
    listchartsdat: [],
    logindata: [],
    logindatatest: [],
    listbranchdata :[],
    addbranchdata : []

}


const UserReducer =  (state = initialState, action) => {
    var _newState;
    switch (action.type) {
        case "DASHBOARD":
            return {
                ...state,
                dashboarddata: action.dashboarddata,
                dashboardcount: action.dashboardcount,
                loading: false

            };
            break;
        case "DASHBOARD_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;

        case "DASHBOARD_EXPORT":
            return {
                ...state,
                dashboardexportdata: action.dashboarddata,
                dashboardexportcount: action.dashboardcount,
                loading: false
            };
            break;
        case "DASHBOARD_EXPORT_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;


        case "LIST_CHART":
            return {
                ...state,
                listchartsdat: action.listcharts.data,
            };
            break;

        case "LOGIN_TEST_SUCCESS":
            return {
                ...state,
                logindatatest: action.logindatatest,
            };
            break;
        case "LIST_BRANCH":
            return {
                ...state,
                listbranchdata: action.listbranchdata,
            };
            break;
        case "ADD_BRANCH":
            return {
                ...state,
                addbranchdata: action.addbranchdata,
            };
            break;
        case "UPDATE_BRANCH":
            return {
                ...state,
                updatebranch: action.updatebranch,
            };
            break;


        default:
            return state;
    }
};


export default UserReducer;
