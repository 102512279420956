import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import * as userActions from "../actions/User";
import Pagesidebar from "../components/Pagsidebar";
import Selectbox from "../components/Selectbox";
import Loader from "../components/Loader";
import {kycStore} from "../reducers/KYCState";
import {userService} from "../services/user.Services";
import Pagination from "react-js-pagination";
import { CSVLink } from "react-csv";
import Navbar from "../components/Navbar";

class Report extends Component {

    constructor(props) {
        super(props);

        this.state={
            activeFilter: 'completed',
            dateFilter: '',
            ActivePage: 1,
            perpage: '50',
            activeStage: 'all'
        };
        this.checkConfig = this.checkConfig.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);

    }
    checkConfig() {

        if (!kycStore.getState().KYCState.DOB_API) {
            userService.getConfig();
            setTimeout(this.checkConfig, 100)
        } else {
            this.props.listboarddata(this.state.dateFilter,this.state.activeFilter,'all',this.state.perpage,this.state.ActivePage, this.state.activeStage);
            this.props.listexportdata(this.state.dateFilter,this.state.activeFilter,'all');

        }
    }
    handledateFilter = (value) => {
        this.setState({dateFilter: value, ActivePage: 1})
        this.props.listboarddata(value,this.state.activeFilter,'all',this.state.perpage,this.state.ActivePage, this.state.activeStage);
        this.props.listexportdata(value,this.state.activeFilter,'all');

    }
    handlestatusFilter = (value) => {
        this.setState({activeFilter: value, ActivePage: 1})
        this.props.listboarddata(this.state.dateFilter,value,'all',this.state.perpage,this.state.ActivePage, this.state.activeStage);
        this.props.listexportdata(this.state.dateFilter,value,'all');

    }
    componentDidMount() {
        setTimeout(this.checkConfig, 100);
        if(!localStorage.getItem('id_token')){
            window.location = "/";
        }
    }
    handlePageChange = (pageNumber) =>{
        this.setState({ActivePage:pageNumber});
        this.props.listboarddata(this.state.dateFilter,this.state.activeFilter,'all',this.state.perpage,pageNumber, this.state.activeStage);
    }
    handlestageFilter = (value) => {
        this.setState({activeStage: value, ActivePage: 1})
        this.props.listboarddata('', this.state.activeFilter,this.state.nationality,this.state.perpage,this.state.ActivePage, value);


    }


    render() {
        const sortbystagefilter = [
            {"label": "All", "value": "all"},
            {"label": "introduction", "value": "introduction"},
            {"label": "primary-details", "value": "primary-details"},
            {"label": "verify-otp", "value": "verify-otp"},
            {"label": "fatca-pep", "value": "fatca-pep"},
            {"label": "Account exist in T24", "value": "Account exist in T24"},
            {"label": "id-scan", "value": "id-scan"},
            {"label": "id-scan-complete", "value": "id-scan-complete"},
            {"label": "wrong-id-error", "value": "wrong-id-error"},
            {"label": "extract-id-error", "value": "extract-id-error"},
            {"label": "passport-scan", "value": "passport-scan"},
            {"label": "passport-scan-complete", "value": "passport-scan-complete"},
            {"label": "wrong-passport-error", "value": "wrong-passport-error"},
            {"label": "extract-passport-error", "value": "extract-passport-error"},
            {"label": "passport-scan-complete", "value": "passport-scan-complete"},
            {"label": "liveness-info", "value": "liveness-info"},
            {"label": "liveness-gestures", "value": "liveness-gestures"},
            {"label": "user-details", "value": "user-details"},
            {"label": "personal-details", "value": "personal-details"},
            {"label": "employment-details", "value": "employment-details"},
            {"label": "residence-details", "value": "residence-details"},
            {"label": "employment-details", "value": "employment-details"},
            {"label": "preview", "value": "preview"},
            {"label": "thankyou", "value": "thankyou"}];
        const sortbyfilter = [{"label": "All", "value": ""}, {"label": "Today", "value": "1"},{"label": "Last 7 days", "value": "7"},{"label": "Last 30 Days", "value": "30"}];
        const sortbystatusfilter = [{"label": "Completed", "value": "completed"},{"label": "Abandoned", "value": "abandoned"}];
        let resultdata = this.props.dashboarddata ||[];
        let exportdata = this.props.dashboardexportdata ||[];
        let totalCount = this.props.dashboardcount ||0;

        //console.log(resultdata)
        //console.log( resultdata)
        //console.log( exportdata)
        return (

            <React.Fragment>
                <Pagesidebar  menu="report" mainmenu="Report" showsidebar={true}/>
                <div className="pageSidebarOffset">
                    <navbar className="pageNav">
                        <Navbar pagettl="Report"  showsidebar={true} filter="No" search="No"   />
                    </navbar>
                    {this.props.loading &&
                    <Loader/>
                    }
                    <div className="roundBox">
                        <div className="roundBox__head flexCS">
                            <h4 className="marg10"> List ({totalCount})</h4>

                            <div className="sortBox__check flexCB csLabeled">
                                <label  className="csForm__item__label">Filter By Date :</label>
                                <Selectbox label="Date filter" name="dateFilter"  labelStatus = "No" values={sortbyfilter}  onChange= {this.handledateFilter}/>
                            </div>
                            <div className="sortBox__check flexCB csLabeled">
                                <label  className="csForm__item__label">Status :</label>
                                <Selectbox label="Status filter" name="statusFilter"  labelStatus = "No" values={sortbystatusfilter}  onChange= {this.handlestatusFilter}/>
                            </div>
                            {this.state.activeFilter === 'abandoned' &&
                            <div className="sortBox__check flexCB csLabeled">
                                <label className="csForm__item__label">Stage :</label>
                                <Selectbox label="Stage filter" name="stageFilter" labelStatus="No"
                                           values={sortbystagefilter} onChange={this.handlestageFilter} value={this.state.activeStage}/>
                            </div>
                            }
                            {exportdata.length > 0 &&
                            <CSVLink
                                data={exportdata}
                                filename={"Report.xls"}
                                className={"btn btn--primaryG btn--sm btn--capsule"}
                                target="_blank"
                            >
                                Export as Excel
                            </CSVLink>
                            }

                        </div>

                        {/*<div className='sortBox'>*/}


                        {/*    <div>*/}
                        {/*        <div className="flexCC buttonWrap">*/}
                        {/*            <span  className="btn btn--primaryG btn--sm btn--capsule">Go</span>*/}
                        {/*            <span  className="btn btn--primaryOuter btn--sm btn--capsule">Reset</span>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {totalCount > this.state.perpage &&
                        <div style={{padding: '0px 30px'}}>
                            <Pagination
                                activePage={this.state.ActivePage}
                                itemsCountPerPage={this.state.perpage}
                                totalItemsCount={totalCount}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange}
                            />
                        </div>
                        }

                        <div className="tableWrapper scrollable heighted">
                            <table className="csTable">
                                <thead>
                                <tr>
                                    <th>Sl No</th>
                                    <th></th>
                                    <th>Name</th>
                                    {this.state.activeFilter === 'completed' &&
                                    <th>CIF</th>
                                    }
                                    <th>Nationality</th>
                                    <th>Mobile</th>
                                    <th>Email</th>
                                    {this.state.activeFilter === 'abandoned' &&
                                    <th>Stage</th>
                                    }
                                    <th>Journey Id</th>
                                    <th>Created At</th>
                                    {this.state.activeFilter === 'completed' &&
                                    <>
                                        <th>DOB</th>
                                        <th>Gender</th>
                                        <th>National ID</th>
                                        <th>Branch Name</th>
                                        <th>Branch Code</th>
                                    </>
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {resultdata.length > 0 && resultdata.map((list,index) =>
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td></td>
                                        <td>{list.name}</td>
                                        {this.state.activeFilter === 'completed' &&
                                        <td>{list.cif}</td>
                                        }
                                        <td>{list.nationality}</td>
                                        <td>{list.mobile}</td>
                                        <td>{list.email}</td>
                                        {this.state.activeFilter === 'abandoned' &&
                                        <td>{list.stage}</td>
                                        }
                                        <td>{list.journey_id}</td>
                                        <td>{list.created_at}</td>
                                        {this.state.activeFilter === 'completed' &&
                                        <>
                                            <td>{list.dob}</td>
                                            <td>{list.gender}</td>
                                            <td>{list.national_id}</td>
                                            <td>{list.branch_name}</td>
                                            <td>{list.branch_code}</td>
                                        </>
                                        }

                                    </tr>
                                )}


                                {resultdata.length == 0 &&
                                <tr>

                                    <td colSpan="10" style={{textAlign:'center'}}>No record found</td>

                                </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </React.Fragment>

        )
    }
}
const mapStateToProps = state => ({
    dashboarddata: state.UserReducer.dashboarddata,
    dashboardcount: state.UserReducer.dashboardcount,
    dashboardexportdata: state.UserReducer.dashboardexportdata,
    exportdata: state.UserReducer.ExportData,
    loading: state.UserReducer.loading

});
export default  withRouter(connect(mapStateToProps, {
    listboarddata:userActions.ListDashboard,
    listexportdata:userActions.ListExportData

})(Report));
